<template>
	<section class="container">
		<div class="row justify-content-end">
			<div class="col-auto">
				<BaseActionButton type="button" v-on:on-click="setEditable" v-if="$can('update', 'Company', 'CompanyInfo')">
					<font-awesome-icon :icon="['far', 'edit']" class="icon" />
				</BaseActionButton>
			</div>
		</div>
		<b-card>
			<div class="row">
				<b-form @submit.prevent="onSubmit" class="col-12">
					<!--Info-->
					<div class="row justify-content-center">
						<div class="col-lg-6 col-12">
							<company-info v-model="company.companyInfo" :isNew="false" :disabled="disabled" />
							<div v-show="$can('read', 'Company', 'Comment')">
								<label>
									<b>Kommentar omkring virksomheden</b>
								</label>
								<InputTextArea v-model="company.comment" :disabled="disabled"></InputTextArea>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 mt-5">
							<BaseActionButton
								class="btn-update"
								v-bind:clicked="clicked"
								v-bind:disabled="disabled || clicked"
								type="submit"
								v-if="$can('update', 'Company', 'CompanyInfo')"
							>
								{{ $t('global.update') }}
							</BaseActionButton>
						</div>
					</div>
				</b-form>
			</div>
		</b-card>
	</section>
</template>
<script>
import CompanyInfo from '@/modules/general/company/components/CompanyInfo'
import InputTextArea from '../../../global/atomComponents/InputTextArea.vue'

export default {
	name: 'CompanyEdit',
	components: {
		CompanyInfo,
		InputTextArea,
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	data() {
		return {
			disabled: true,
			clicked: false,
		}
	},
	computed: {
		id() {
			return this.$route.params.companyId
		},
		company() {
			return this.$store.getters['companyVuex/company']
		},
	},
	methods: {
		async onSubmit() {
			this.clicked = true
			var data = this.company
			var id = this.id
			try {
				var result = await this.$store.dispatch('companyVuex/updateCompany', {
					data,
					id,
				})
				this.clicked = false
				this.toast('Success', 'Et selskab nu opdateret', true)
			} catch (error) {
				this.clicked = false
				this.toast(error.response.data.code, error.response.data.message, false)
			}
		},
		setEditable() {
			this.disabled = !this.disabled
		},
	},
}
</script>
<style lang="scss">
@import '@/styles/pages/companyAdd.scss';
</style>
